
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-4 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.categories.edit_path">
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                {{ item.title }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <form @submit.prevent="updateCategories" autocomplete="off">
                <v-layout row wrap>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="categories.category_name" type="text" :label="$store.getters.language.data.categories.category_name" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-autocomplete v-model="categories.file_attachment_id" :label="$store.getters.language.data.categories.file_attachment" dense
                            class="mx-1"  filled outlined :items="file_attachments" item-text="file_attachment_name" item-value="file_attachment_id">
                            <template v-slot:item="{item}">
                                <v-img :src="$image_url + 'file_attachments/serve_file/' + item.file_attachment_path" contain max-width="70" class="mb-2"></v-img>
                                <span class="ms-1"> {{ item.file_attachment_name }} </span>
                            </template>
                        </v-autocomplete>
                    </v-flex> -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="[{text: $store.getters.language.data.enabled, value: 'enable'}, {text: $store.getters.language.data.disabled, value: 'disable'}]" v-model="categories.category_status" dense  filled outlined item-text="text"
                            item-value="value" :return-object="false" :label="$store.getters.language.data.categories.category_status">
                        </v-select>
                    </v-flex>
             -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="[{text: $store.getters.language.data.yes, value: 'true'}, {text: $store.getters.language.data.no, value: 'false'}]" v-model="categories.available_to_shop" dense  filled outlined item-text="text"
                            item-value="value" :return-object="false" :label="$store.getters.language.data.categories.available_to_shop">
                        </v-select>
                    </v-flex> -->
                
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select v-model="categories.category_parent" :label="$store.getters.language.data.categories.category_parent" dense
                            class="mx-1" filled outlined :items="categories_list" item-text="category_name" item-value="category_id" clearable>
                        </v-select>
                    </v-flex> -->

                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="success" type="submit" >{{$store.getters.language.data.categories.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/categories.request.js'
    export default {
        data() {
            return {
                categories: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            file_attachments(){
                return this.$store.getters.file_attachments_list
            },
            categories_list() {
                return this.$store.getters.categories_list
            },
            buttonDisable() {
                return !this.categories.category_name ||
                    !this.categories.available_to_shop ||
                    !this.categories.category_status
            }
        },
        mounted(){
            this.id = this.$route.params.id
            if(this.$store.state.store_initialized){
                this.getOneCategories()
            }
        },
        methods: {
            getOneCategories() {
                this.loading = true
                requests.getOneCategories(this.id).then(r =>{
                    if (r.status == 200) {
                        this.categories = r.data.row
                        this.categories.file_attachment_id = Number(r.data.row.file_attachment_id) || null
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
                
            },
            updateCategories() {
                this.loading = true
                requests.updateCategories(this.id,this.categories).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.update_success,
                            color: 'success'
                        }
                        let index = this.$store.getters.categories_list.findIndex(obj => obj.category_id == this.id);
                        this.$store.state.categories_list[index] = r.data.new_data
                        this.loading = false
                        this.$router.push('/categories-list');
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    